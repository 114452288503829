import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import books from "./books.json";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function TranslatedBooksSection() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Traducător</h2>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.section}>
        {/*<List className={useAddonStyles.root}>*/}
          <GridContainer>
          {
            books.map(book => {
              return (
                // <ListItem alignItems="flex-start">
                <GridItem xs={12} sm={12} md={4}>
                  {/*<ListItemAvatar>*/}
                  {/*  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"/>*/}
                  {/*</ListItemAvatar>*/}
                  <ListItemText
                    primary={book.title}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {book.author}
                        </Typography>
                        {book.publisher ? " ("+book.publisher+")" : null}
                      </React.Fragment>
                    }
                  />
                {/*</ListItem>*/}
                </GridItem>
              )
            })
          }
        {/*</List>*/}
          </GridContainer>
      </div>
    </div>
  );
}
