import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import theatre from "./theatre.json";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function PlaysSection() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Teatru</h2>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.section}>
        {/*<List className={useAddonStyles.root}>*/}
        <GridContainer>
          {
            theatre.map(categories => {
              return (
                // <ListItem alignItems="flex-start">
                <GridItem xs={12} sm={12} md={4} key={categories.category}>
                  {/*<ListItemAvatar>*/}
                  {/*  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"/>*/}
                  {/*</ListItemAvatar>*/}
                  <ListItemText
                    primary={categories.category}
                    secondary={
                      categories.authors.map(authors => {
                        return (
                          <React.Fragment key={authors.author}>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {authors.author}
                            </Typography>
                            <br/>
                            {
                              authors.titles.map(titles => {
                                return (
                                  <span key={titles.title}>
                                    {titles.title ? titles.title : null}
                                    <br/>
                                  </span>
                                )
                              })
                            }
                            <br/>
                          </React.Fragment>)
                      })
                    }
                  />
                  {/*</ListItem>*/}
                </GridItem>
              )
            })
          }
          {/*</List>*/}
        </GridContainer>
      </div>
    </div>
  );
}
