import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
import Translate from "@material-ui/icons/Translate";
import Create from "@material-ui/icons/Create";
import Assessment from "@material-ui/icons/Assessment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import Button from "../../../components/CustomButtons/Button";

const useStyles = makeStyles(styles);

export default function ActivitySection() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 className={classes.title}>Activitate</h2>
                    {/*<h5 className={classes.description}>*/}
                    {/*    ... TEXT ... putin mai detaliat ... autor, traducator ...*/}
                    {/*</h5>*/}
                    <Button color="success" round size="lg" href="/literature" target="_self">
                        Literatura
                    </Button>
                    <Button color="info" round size="lg" href="/theatre" target="_self">
                        Teatru
                    </Button>
                    <Button color="danger" round size="lg" href="/about" target="_self">
                        Biografie
                    </Button>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <InfoArea
                        title="Traducator"
                        description="A peste 60 de carți și piese de teatru din dramaturgia spaniolă şi franceză. Piesele traduse se joacă la Teatrul Național, Teatrul Nottara, Teatrul Bulandra, Teatrul Excelsior, și multe altele..."
                        icon={Translate}
                        iconColor="info"
                        vertical
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <InfoArea
                        title="Scriitor"
                        description="Cărți, piese de teatru, cu lansări naționale și internaționale"
                        icon={Create}
                        iconColor="success"
                        vertical
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <InfoArea
                        title="Concursuri"
                        description=""
                        icon={Assessment}
                        iconColor="danger"
                        vertical
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}
