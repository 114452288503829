import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/Luminita.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function AboutPage(props) {
  const classes = useStyles();
  const {...rest} = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div>
      <Header
        color="transparent"
        brand="Luminița Voina - Răuț"
        rightLinks={<HeaderLinks/>}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/silhouettes-trees-people-wood-4mp.jpg")}/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses}/>
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Luminița Voina - Răuț</h3>
                    <h6>TRADUCATOR, SCRIITOR</h6>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-twitter"}/>
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"}/>
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"}/>
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                S-a născut la Braşov, în 1958. Este căsătorită şi are o fiică.
                <br/>
                A absolvit în 1981 Facultatea de Limbi şi Literaturi străine, Universitatea Bucureşti
                <br/>
                Este membră a Uniunii Scriitorilor din România din 1995
                <br/>
                În 2010 a publicat prima carte
              </p>
            </div>
            <div>
              <p>
                <br/>
              </p>
              <h3>Biografie:</h3>
              <p>
                1992 – a fost consilier cultural la Pavilionul României la Expoziția Universală de la Sevilla, Spania
              </p>
              <p>
                A făcut parte din comisia de jurizare a dosarelor candidaţilor pentru burse, din cadrul programului de traducători profesionişti, în 2011 şi 2012.
              </p>
              <p>
                2012- prezent: Membru în comitetul director al Filialei Traduceri literare a Uniunii Scriitorilor din București.
              </p>
              <p>
                A tradus autori importanti: Julio Cortazar, Ernesto Sabato, Mario Vargas Llosa, Alfredo Bryce Echenique, Pablo Neruda, Luis Bunuel, Andres Barba, Juan Marse, Andres Barba etc.
              </p>
              <p>
                A tradus peste 60 de piese de teatru din dramaturgi importanţi: Juan Mayorga, Sergi Belbel, Jordi Galcerán, Esteve Soler, Albert Espinosa, Montse Cornet, Rodrigo García, Luis Alonso de Santos, Jaime Salom, Jorge Mateus, dar şi din dramaturgia franceză: Labiche, Eugen Ionescu etc. (În prezent piese traduse de ea se joacă la Teatrul Național, Teatrul Nottara, Teatrul Bulandra, Teatrul Excelsior - Bucureşti - Teatrul Radu Stanca din Sibiu, Teatrul Sică Alexandrescu din Braşov, Teatrul din Tg Mureş).
              </p>
              <p>
                Are legături cu lumea hispanică, cunoscând personal scriitori spanioli şi latinoamericani: Mario Vargas Llosa, Aurora Bernardez Cortazar, Nuria Amat, Agustin Sanchez Vidal, Juan Marse, Juan Mayorga, Esteve Soler etc.
              </p>
              <p>
                A publicat traduceri, articole, recenzii, eseuri în: "România literară", "Dilema", "Lettre Internationale", "Observator cultural", "Dilemateca" etc.
              </p>
              <p>
                A tradus timp de peste 6 ani articolele scrise de Mario Vargas Llosa în „El País”, pentru revista Money Express.
              </p>
              <h3>Nominalizări premii:</h3>
              <p>
                <br/>
                2002 – Premiile Asociaţiei Editorilor din România pentru traducerea volumului Orgia perpetuă, Flaubert
                şi Doamna Bovary de Mario Vargas Llosa;
                <br/>
                2003 – Premiile Asociaţiei Scriitorilor din România, împreună cu Mariana Sipoş pentru traducerea cărţii
                Sărbătoarea Ţapului de Mario Vargas Llosa;
                <br/>
                2006 – Premiile Uniunii Scriitorilor din România pentru traducerea volumului Adevărul minciunilor de
                Mario Vargas Llosa; martie
                <br/>
                2011 – Diplomă de excelenţă pentru traduceri, acordată de revista Observator cultural, în cadrul Galei
                Premiilor Observatorului Cultural; octombrie
                <br/>
                2011 – Diplomă şi medalie de onoare pentru traduceri, acordate de Ambasada Spaniei la Bucureşti.
                <br/>
                - În 2016 și în 2018 a fost invitată la Salonul de carte de teatru de la Madrid.
                <br/>
                - Din 2016 până în 2019 a fost invitată la  Muestra de Teatro Español de Autores Contemporáneos de Alicante (Festivalul de teatru spaniol contemporan, din Alicante).
                <br/>
                - din 2017 face parte din "The Fence"  - o retea internationala pentru dramaturgi si oameni de teatru ("An International network for working playwrights and people who make playwriting happen")
                <br/>
                - din 2018 face parte din Eurodram - retea europeana pentru traducerea pieselor de teatru ("European network for drama in translation")
              </p>
              <p>
                <br/>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
