/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import {List, ListItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href={"/about"}
                className={classes.block}
                target="_self"
              >
                Biografie
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href={"/literature"}
                className={classes.block}
                target="_self"
              >
                Literatură
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href={"/theatre"}
                className={classes.block}
                target="_self"
              >
                Teatru
              </a>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()} - Toate drepturile rezervate
          <br />
          <br />
          Theme by {" "}
          <a
            href="https://www.creative-tim.com?ref=mkr-footer"
            className={aClasses}
            target="_blank"
          >
            Creative Tim
          </a>
          <br />Main photo by <a href="https://www.freepik.com/jannoon028">jannoon028</a> from <a href="https://www.freepik.com/photos/business">Freepik</a>
        </div>

      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
